<template>
  <!-- 数字通证管理 -->
  <div>
    <!-- 新增按钮 -->
    <div class="onBtn">
      <el-button type="success" style="margin-left: auto" @click='addGen' >新增</el-button>
    </div>
    <!-- 内容区域 -->
    <div>
      <el-table :data="form" border style="width: 100%">
        <el-table-column prop="tokenName" label="数字通证名称"  align="center"> </el-table-column>
        <el-table-column prop="tokenNum" label="数字通证编号"  align="center"> </el-table-column>
        <el-table-column prop="price" label="原价区价格"  align="center"> </el-table-column>
        <el-table-column prop="agreementPrice" label="协议区价格"  align="center"> </el-table-column>
        <el-table-column prop="rate" label="扣除差价百分比"  align="center"> </el-table-column>
        <el-table-column prop="rockTime" label="锁仓天数"  align="center"> </el-table-column>
        <el-table-column prop="shopRate" label="商城流入百分比"  align="center"> </el-table-column>
        
        <el-table-column prop="status" 
        label="状态"  align="center">
            <template v-slot="{row}">
                {{row.status==1?'下线':'上线'}}
            </template>
        </el-table-column>
        <el-table-column prop="" 
        label="操作"  align="center">
            <template v-slot="{row}">
                <el-button @click='editInfo(row)' type='text'>编辑</el-button>
                <el-button @click='editInfo2(row)' type='text'>通证配置</el-button>
            </template>
        </el-table-column>
      </el-table>
    </div>

     <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
   data(){
    return{
        form:[],
        total:0,
        pageSize:10,
        currentPage:1
    }
   },
   mounted(){
    this.getList()
   },
   methods:{
    getList(){
         this.axios
        .get('/admin/gen/findAll', {
          pageNumber: this.currentPage,
          pageSize: this.pageSize
        }).then(res=>{
            this.form=res.data.data.records
            this.total=res.data.data.total
        })
    },
    addGen(){
         this.$router.push('editGen')
    },
    editInfo(row){
        this.$router.push({path:'editGen',query:{id:row.id}})
    },
    editInfo2(row){
         this.$router.push({path:'genAllocation',query:{genId:row.id}})
    },
     // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
   }
}
</script>

<style lang="scss" scoped>
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  div {
    margin: 0 3px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>